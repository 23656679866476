import React from 'react';
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, ButtonBase, Icon, styled } from "@mui/material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { Paragraph, Span } from "../Typography";

const ExtAndIntCommon = {
    display: "flex",
    overflow: "hidden",
    borderRadius: "4px",
    height: 44,
    whiteSpace: "pre",
    marginBottom: "8px",
    textDecoration: "none",
    justifyContent: "space-between",
    transition: "all 150ms ease-in",
    "&:hover": { background: "rgba(255, 255, 255, 0.08)" },
    "&.compactNavItem": {
        overflow: "hidden",
        justifyContent: "center !important"
    },
    "& .icon": {
        fontSize: "18px",
        paddingLeft: "16px",
        paddingRight: "16px",
        verticalAlign: "middle"
    }
};

const InternalLink = styled(Box)(({ theme }) => ({
    "& a": {
        ...ExtAndIntCommon,
        color: theme.palette.text.primary
    },
    "& .navItemActive": {
        backgroundColor: "rgba(255, 255, 255, 0.16)"
    }
}));

const BulletIcon = styled("div")(({ theme }) => ({
    padding: "2px",
    marginLeft: "24px",
    marginRight: "8px",
    overflow: "hidden",
    borderRadius: "300px",
    background: theme.palette.text.primary
}));

const StyledText = styled(Span)(({ mode }) => ({
    fontSize: "0.875rem",
    paddingLeft: "0.8rem",
    display: mode === "compact" && "none"
}));

const BadgeValue = styled("div")(() => ({
    padding: "1px 8px",
    overflow: "hidden",
    borderRadius: "300px"
}));


const ChildNav = ({ item, index, mode }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <InternalLink key={index}>
            <NavLink
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                to={item.path}
                className={({ isActive }) =>
                    isActive
                        ? `navItemActive ${mode === "compact" && "compactNavItem"}`
                        : `${mode === "compact" && "compactNavItem"}`
                }>
                <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
                    {item?.icon ? (
                        <Icon className="icon" sx={{ width: 36 }}>
                            {item.icon}
                        </Icon>
                    ) : (
                        <Fragment>
                            <BulletIcon
                                className={`nav-bullet`}
                                sx={{ display: mode === "compact" && "none" }}
                            />
                            <Box
                                className="nav-bullet-text"
                                sx={{
                                    ml: "20px",
                                    fontSize: "11px",
                                    display: mode !== "compact" && "none"
                                }}>
                                {item.iconText}
                            </Box>
                        </Fragment>
                    )}
                    <StyledText mode={mode} className="sidenavHoverShow">
                        {item.name}
                    </StyledText>

                    <Box mx="auto" />

                    {item.badge && (
                        <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                    )}
                </ButtonBase>
            </NavLink>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open && (mode === "compact")}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{item.name}</Typography>
            </Popover>
        </InternalLink>
    )
}

export default ChildNav