import { lazy } from "react";
import Loadable from "app/components/Loadable";

const DashboardAttendance = Loadable(lazy(() => import("./Attendance")));
const DetailAttendance = Loadable(lazy(() => import("./Detail/index")));

const attendanceRoutes = [
  { path: "/attendance/summary", element: <DashboardAttendance /> },
  { path: "/attendance/detail", element: <DetailAttendance /> },
];

export default attendanceRoutes;
