import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// import materialRoutes from "app/views/material-kit/MaterialRoutes";
import masterRoutes from "./views/master/masterRoute";
import analyticRoutes from "./views/analytic/analyticRoute";
import attendanceRoutes from "./views/attendance/attendanceRoutes";
import competenceRoutes from "./views/competence/competenceRoute";
import profilingRoutes from "./views/profiling/profilingRoutes";
import useAuth from "./hooks/useAuth";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister")),
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword")),
);
// E-CHART PAGE
// const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(
  lazy(() => import("app/views/dashboard_template/Analytics")),
);
const PersonalInfo = Loadable(
  lazy(() => import("app/views/master/Employee/personalInfo")),
);
const RootRouteHandler = () => {
  const { user } = useAuth();

  // Check if the user has a token
  if (user && user.default_menu) {
    return <Navigate to={user.default_menu} />;
  } else {
    return <Navigate to="signin" />;
  }
};

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      ...masterRoutes,
      ...analyticRoutes,
      ...attendanceRoutes,
      ...competenceRoutes,
      ...profilingRoutes,
      // dashboard route
      // { path: "/dashboard", element: <Analytics />, auth: authRoles.admin }
      // { path: "/", element: <Dashboard />, auth: authRoles.admin },
      // { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor }
    ],
  },

  // session pages route
  {
    path: "/formdatadiri/:employeeId",
    element: <PersonalInfo />,
  },
  { path: "/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <RootRouteHandler /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
