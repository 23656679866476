import { useCallback, useEffect, useRef, useState } from "react";
import { ButtonBase, Icon, Box, styled } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HoverChildNav from "./HoverChildNav";
import clsx from "clsx";

// STYLED COMPONENTS
const NavExpandRoot = styled("div")(({ theme }) => ({
  "& .expandIcon": {
    transition: "transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  "& .collapseIcon": {
    transition: "transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  },
  "& .expansion-panel": {
    overflow: "hidden",
    transition: "max-height 0.3s cubic-bezier(0, 0, 0.2, 1)"
  },
  "& .highlight": {
    background: theme.palette.primary.main
  },
  "&.compactNavItem": {
    width: 44,
    overflow: "hidden",
    justifyContent: "center !important",
    "& .itemText": { display: "none" },
    "& .itemIcon": { display: "none" }
  }
}));

const BaseButton = styled(ButtonBase)(({ theme }) => ({
  height: 44,
  width: "100%",
  whiteSpace: "pre",
  overflow: "hidden",
  // paddingRight: "16px",
  borderRadius: "4px",
  marginBottom: "8px",
  display: "flex",
  justifyContent: "space-between !important",
  color: theme.palette.text.primary,
  "&:hover": { background: "rgba(255, 255, 255, 0.08)" },
  "& .icon": {
    width: 36,
    fontSize: "27px",
    paddingLeft: "16px",
    paddingRight: "16px",
    verticalAlign: "middle"
  }
}));

const BulletIcon = styled("div")(({ theme }) => ({
  width: 4,
  height: 4,
  color: "inherit",
  overflow: "hidden",
  marginLeft: "20px",
  marginRight: "8px",
  borderRadius: "300px !important",
  // background: theme.palette.primary.contrastText,
  background: theme.palette.text.primary
}));

const ItemText = styled("span")(() => ({
  fontSize: "0.875rem",
  paddingLeft: "0.8rem",
  verticalAlign: "middle"
}));

const SmallItemText = styled("span")(() => ({
  fontSize: "0.475rem",
  marginTop: "2.5px",
  // paddingLeft: "0.8rem",
  // verticalAlign: "middle",
  width: '100%'
}));

const BadgeValue = styled("div")(() => ({
  padding: "1px 4px",
  overflow: "hidden",
  borderRadius: "300px"
}));

export default function MatxVerticalNavExpansionPanel({ item, children, hoverChildren, mode }) {
  // console.log("children ->", children);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [collapsed, setCollapsed] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  const elementRef = useRef(null);
  const componentHeight = useRef(0);
  const { pathname } = useLocation();
  const { name, icon, iconText, badge } = item;

  const handleClick = (event) => {
    componentHeight.current = 0;
    calculateHeight(elementRef.current);
    setCollapsed(!collapsed);
    if (mode === "compact") {
      handlePopoverOpen(event);
    }
  };

  const calculateHeight = useCallback((node) => {
    if (node.name !== "child") {
      for (let child of node.children) {
        calculateHeight(child);
      }
    }

    if (node.name === "child") componentHeight.current += node.scrollHeight;
    else componentHeight.current += 44; //here 44 is node height
    return;
  }, []);

  useEffect(() => {
    if (!elementRef) return;

    calculateHeight(elementRef.current);

    // OPEN DROPDOWN IF CHILD IS ACTIVE
    for (let child of elementRef.current.children) {
      // console.log("element ref-> ", elementRef)
      if (child.getAttribute("href") === pathname) {
        setCollapsed(false);
        // setActiveStatus(true);
      }
      // else {
      //   setActiveStatus(false);
      // }
    }
  }, [pathname, calculateHeight]);

  return (
    <NavExpandRoot
      sx={{
        // backgroundColor: (activeStatus) ? "rgba(255, 255, 255, 0.16)" : "",
        marginLeft: (mode === "compact") ? -1 : 0,
        marginRight: (mode === "compact") ? -1 : 0
      }}
    >
      <BaseButton
        className={clsx({
          "has-submenu compactNavItem": true,
          compactNavItem: mode === "compact",
          open: !collapsed
        })}
        style={{ flexDirection: (mode === "compact") ? 'column' : '', alignItems: (mode === "compact") ? 'center' : '' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        onClick={handleClick}>
        <Box display="flex" alignItems="center">
          <Box alignContent="center" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {icon && <Icon sx={{ display: 'flex', justifyContent: 'center' }} className="icon">{icon}</Icon>}
            {iconText && <BulletIcon />}
            {(mode === "compact") ?
              <SmallItemText>{name}</SmallItemText> :
              <></>
            }
          </Box>
          {
            (mode !== "compact") ?
              <ItemText className="sidenavHoverShow">{name}</ItemText> :
              <></>
          }
        </Box>

        {badge && <BadgeValue className="sidenavHoverShow itemIcon">{badge.value}</BadgeValue>}

        {
          (mode !== "compact") ?
            <div
              className={clsx({
                sidenavHoverShow: true,
                collapseIcon: collapsed,
                expandIcon: !collapsed
              })}>
              <ChevronRight fontSize="small" sx={{ verticalAlign: "middle" }} />
            </div> :
            <></>
        }

      </BaseButton>

      <div
        ref={elementRef}
        className="expansion-panel submenu"
        style={(collapsed || (mode === "compact")) ? { maxHeight: "0px" } : { maxHeight: componentHeight.current + "px" }}>
        {children}
      </div>

      <Popover
        id="mouse-over-popover"
        // sx={{
        //   pointerEvents: 'none',
        // }}
        // onMouseLeave={handlePopoverClose}
        open={open && (mode === "compact")}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          // ref={elementRef}
          // className="expansion-panel submenu"
          sx={{ maxHeight: componentHeight.current + "px", minWidth: 250 }}>
          <Box display="flex" alignItems="center" sx={{ height: 50 }}>
            <ItemText className="sidenavHoverShow">{name}</ItemText>
          </Box>
          {hoverChildren.map((item, index) => {
            return (
              <HoverChildNav functionClick={handlePopoverClose} item={item} index={index} />
            );
          })}
        </Box>
        {/* <Typography sx={{ p: 1 }}>{name}</Typography> */}
      </Popover>
    </NavExpandRoot>
  );
}
