import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const baseURL = `${process.env.REACT_APP_BASE_API_URL}`;

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const deleteCookie = (name) => {
    document.cookie = name + "=; Max-Age=0; path=/";
  };

  const login = async (username, password) => {
    const response = await axios.post(
      `${baseURL}login/auth`,
      {
        username,
        password,
      },
      {
        withCredentials: true, // Sertakan kredensial dalam permintaan
      },
    );
    const { data } = response.data;
    localStorage.setItem("user", JSON.stringify(data));
    dispatch({ type: "LOGIN", payload: { user: data } });
    return response?.data;
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });
    const { user } = response.data;

    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("user");
    deleteCookie("BNPB");
  };

  useEffect(() => {
    (async () => {
      try {
        // const { data } = await axios.get("/api/auth/profile");
        const token = JSON.parse(localStorage.getItem("user"));

        // localStorage.setItem("user", JSON.stringify(data));

        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: token },
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
