import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Employee = Loadable(lazy(() => import("./Employee/Employee")));
const AddEditEmployee = Loadable(
  lazy(() => import("./Employee/AddEditEmployee")),
);
const Skills = Loadable(lazy(() => import("./Skills/Skills")));
const Organization = Loadable(
  lazy(() => import("./Organization/Organization")),
);
const Education = Loadable(lazy(() => import("./Education/Education")));
const User = Loadable(lazy(() => import("./User/index")));

const masterRoutes = [
  { path: "/master/employee", element: <Employee /> },
  { path: "/master/employee/add", element: <AddEditEmployee /> },
  { path: "/master/employee/edit/:employeeId", element: <AddEditEmployee /> },
  { path: "/master/skills", element: <Skills /> },
  { path: "/master/organization", element: <Organization /> },
  { path: "/master/education", element: <Education /> },
  { path: "/master/user", element: <User /> },
];

export default masterRoutes;
