import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Aggregate = Loadable(lazy(() => import("./Aggregate/index")));
const Individu = Loadable(lazy(() => import("./Individu/index")));
const DashboardCompetence = Loadable(lazy(() => import("./Dashboard/index")));

const competenceRoutes = [
  { path: "/competence/dashboard", element: <DashboardCompetence /> },
  { path: "/competence/aggregate", element: <Aggregate /> },
  { path: "/competence/individu", element: <Individu /> },
];

export default competenceRoutes;
