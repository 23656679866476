import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Retired = Loadable(lazy(() => import("./Retired/index")));
const Fulfillment = Loadable(lazy(() => import("./Fulfillment/index")));
const AssignmentLocation = Loadable(lazy(() => import("./AssignmentLocation/index")));

const analyticRoutes = [
    { path: "/analytic/retired", element: <Retired /> },
    { path: "/analytic/fulfillment", element: <Fulfillment /> },
    { path: "/analytic/location", element: <AssignmentLocation /> },
];

export default analyticRoutes;