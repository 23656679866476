import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER
import "../fake-db";
import { transform } from "lodash";

export default function App() {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        {/* <style>
          {`
            html {
              transform: scale() translate(0, 0);
              zoom: 0.8;
            }
          `}
        </style> */}
        {/* <div style={{ transform: "scale(0.80)" }}> */}
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
        {/* </div> */}
      </AuthProvider>
    </SettingsProvider>
  );
}
