import { Navigate, useLocation } from "react-router-dom";
// HOOK
import useAuth from "app/hooks/useAuth";

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const token = JSON.parse(localStorage.getItem('user'));
  const baseURL = `${process.env.REACT_APP_BASE_API_FE}`;

  if (baseURL === 'http://localhost:3000/fe') {
    if (token && isAuthenticated) return <>{children}</>;
  } else {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };
    const userCookie = getCookie("BNPB");
    if (token && isAuthenticated && userCookie) return <>{children}</>;
  }

  return <Navigate replace to="/signin" state={{ from: pathname }} />;
}
