import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Profiling = Loadable(lazy(() => import("./Profiling")));
const Pivot = Loadable(lazy(() => import("./Pivot")));

const profilingRoutes = [
  { path: "/profiling/summary", element: <Profiling /> },
  { path: "/profiling/pivot", element: <Pivot /> },
];

export default profilingRoutes;
